exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-jsx": () => import("./../../../src/pages/ai.jsx" /* webpackChunkName: "component---src-pages-ai-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-crm-платформа-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/CRM-платформа/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-crm-платформа-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-getback-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/Getback/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-getback-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-kobold-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/kobold/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-kobold-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-macropoker-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/Macropoker/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-macropoker-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-roscontrol-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/roscontrol/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-roscontrol-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-udeu-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/Udeu/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-udeu-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-wigi-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/Wigi/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-wigi-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-оценка-360-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/оценка360/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-оценка-360-index-mdx" */)
}

